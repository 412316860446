module.exports = {
  metadata: {
    title: "Pulso-Media",
    description: "Website created with create-tpws-website",
    author: "https://pulso-media.de",
    generator: "turingpages",
  },

  tpws: {
    environments: {
      development: {
        siteUrl: "http://localhost:9000",
      },
      staging: {
        siteUrl: "https://preview.staging.pulsomediade.pulso-media.de",
      },
      production: {
        siteUrl: "https://pulso-media.de",
      },
    },
    defaultLocale: "de",
    dynamicTemplate: "./src/templates/dynamic-template.js",
    subfont: false,
    allowHtml: true,
  },

  strapi: {
    environments: {
      development: {
        url: "http://localhost:3000",
      },
      staging: {
        url: "https://staging.pulsomediade.pulso-media.de",
        token:
          "16d9750605883177c7e6cdd0df44785f4ae5cb638f19c4d327162a3273f40c6c0afad47cb61d6273665b52a35281fe369a669d499f0d697d7d2068079eabb643d6b365ad14f5b5b3cd806d36bf3e4ce3ebe474bbe675fa2d55753e118d56b72d55b6006ed3508000d1e453f20f55065b219e77461bf0b3b788fd9a2204599f48",
      },
      production: {
        url: "https://pulsomediade.pulso-media.de",
        token:
          "16d9750605883177c7e6cdd0df44785f4ae5cb638f19c4d327162a3273f40c6c0afad47cb61d6273665b52a35281fe369a669d499f0d697d7d2068079eabb643d6b365ad14f5b5b3cd806d36bf3e4ce3ebe474bbe675fa2d55753e118d56b72d55b6006ed3508000d1e453f20f55065b219e77461bf0b3b788fd9a2204599f48",
      },
    },
    collectionTypes: [],
    singleTypes: [],
  },
};
