module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pulso-Media","short_name":"Pulso-Media","start_url":"/","background_color":"#fff","theme_color":"#000","display":"standalone","icon":"./src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"52301b28995d500c32d1ab7ed599a322"},
    },{
      plugin: require('../node_modules/@devular/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"pulso-media.de","proxyScript":"/js/script.js","proxyApi":"/api/event"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
